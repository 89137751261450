import React, { Component } from "react";
import { ReactComponent as Previous } from "../../assets/before.svg";
import { ReactComponent as Next } from "../../assets/next.svg";

class GVPhotography extends Component {
  state = {
    imageArray: [1, 2, 3, 4, 5, 6, 7],
    currentImage: 1,
  };

  clickNext() {
    let { currentImage } = this.state;
    let next = currentImage + 1;
    if (currentImage === 7) {
      this.setState({ currentImage: 1 });
    } else {
      this.setState({ currentImage: next });
    }
  }

  clickPrevious() {
    let { currentImage } = this.state;
    let previous = currentImage - 1;
    if (currentImage === 1) {
      this.setState({ currentImage: 7 });
    } else {
      this.setState({ currentImage: previous });
    }
  }

  render() {
    let { currentImage } = this.state;
    return (
      <div className="row project-row">
        <div className="col-lg-7 project-image-outer-container noselect">
          <div className="project-images-container">
            <img
              className="rounded noselect project-images"
              src={`assets/projects/gvphotography/gv${currentImage}.PNG`}
              alt="eventer mainpage"
            ></img>
          </div>
          <Previous
            onClick={() => this.clickPrevious()}
            style={{
              height: "50px",
              width: "50px",
              position: "absolute",
              top: "45%",
              left: "0",
            }}
            className="prev-button"
          />
          <Next
            onClick={() => this.clickNext()}
            className="next-button"
            style={{
              height: "50px",
              width: "50px",
              position: "absolute",
              top: "45%",
              right: "0",
            }}
          />
        </div>

        <div className="col-lg-5 project-text text-center">
          <p className="par-text">
            GV Photography is my first professional use website. It will be used
            to keep the client's followers and customers up to date with his
            latest photos and life events.
            <br />
            <br />
            For this project, I decided to fine tune my skills with Firebase
            while dedicating more time to using hooks. Luckily I was able to
            setup Redux easily in this project so I could spend more time
            organizing the data efficiently. I wanted to switch off
            Semantic-ui-react because it did not have good mobile compatibility.
            <br />
            <br />
            This was my first project to date using modern technologies while
            creating a seperate Admin and average user interface. I was able to
            setup both front-end and back-end authentication in order to prevent
            unwanted users from saving or deleting items from the database.
            <br />
            <br />
            I hope to continue building skills with Redux and Firebase while
            using a different platform to build my app on. The client has asked
            to keep the repo private as to mitigate security risks.
            <br />
          </p>
          <div stlye={{ justifyContent: "center" }}>
            <a
              href="https://gautamvphotography.web.app/"
              type="button"
              className="btn btn-dark btn-lg"
            >
              Website Link
            </a>
            {/* <a
              href="https://github.com/seanspiesman/Eventer"
              type="button"
              className="btn btn-dark btn-lg"
              style={{
                height: "48px",
                padding: "5px 10px 10px 5px",
                backgroundColor: "white",
                boxGlow: "0px 0px -5px black",
              }}
            >
              <img id="github-logo" src="assets\github.png" alt="github icon" />
            </a> */}
          </div>
        </div>
      </div>
    );
  }
}

export default GVPhotography;
