import React from "react";
import Eventer from "./Projects/Eventer";
import Gigl from "./Projects/Gigl";
import Bandana from "./Projects/Bandana";
import Etsyocalypse from "./Projects/Etsyocalypse";
import GVPhotography from "./Projects/GVPhotography";

const Projects = (props) => {
  return (
    <>
      <div id="projects-background" alt="Photo by Safar Safarov">
        <div id="center-text">Past Projects</div>

        <div className="lower-content">
          <div className="container-fluid">
            <br />
            <ul className="nav nav-tabs" id="myTab" role="tablist">
              <li className="nav-item">
                <a
                  className="nav-link active"
                  id="GVPhoto-tab"
                  data-toggle="tab"
                  href="#GVPhoto"
                  role="tab"
                  aria-controls="GVPhoto"
                  aria-selected="true"
                >
                  GV Photography
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link"
                  id="Eventer-tab"
                  data-toggle="tab"
                  href="#Eventer"
                  role="tab"
                  aria-controls="Eventer"
                  aria-selected="true"
                >
                  Eventer
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link"
                  id="Gigl-tab"
                  data-toggle="tab"
                  href="#Gigl"
                  role="tab"
                  aria-controls="Gigl"
                  aria-selected="false"
                >
                  Gigl
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link "
                  id="Bandana-tab"
                  data-toggle="tab"
                  href="#Bandana"
                  role="tab"
                  aria-controls="Bandana"
                  aria-selected="false"
                >
                  Bandana Nana
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link"
                  id="Etsyocalypse-tab"
                  data-toggle="tab"
                  href="#Etsyocalypse"
                  role="tab"
                  aria-controls="Etsyocalypse"
                  aria-selected="false"
                >
                  Etsyocalypse
                </a>
              </li>
            </ul>
            <div className="tab-content" id="myTabContent">
              <div
                className="tab-pane fade show active"
                id="GVPhoto"
                role="tabpanel"
                aria-labelledby="GVPhoto-tab"
              >
                <GVPhotography />
              </div>
              <div
                className="tab-pane fade"
                id="Eventer"
                role="tabpanel"
                aria-labelledby="Eventer-tab"
              >
                <Eventer />
              </div>
              <div
                className="tab-pane fade"
                id="Gigl"
                role="tabpanel"
                aria-labelledby="Gigl-tab"
              >
                <Gigl />
              </div>
              <div
                className="tab-pane fade"
                id="Bandana"
                role="tabpanel"
                aria-labelledby="Bandana-tab"
              >
                <Bandana />
              </div>
              <div
                className="tab-pane fade"
                id="Etsyocalypse"
                role="tabpanel"
                aria-labelledby="Etsyocalypse-tab"
              >
                <Etsyocalypse />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Projects;
