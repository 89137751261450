import React, { Component } from "react";

class About extends Component {
  state = {
    imageArray: [],
    currentImage: 0,
  };

  render() {
    return (
      <>
        <div id="about-background" alt="Photo by Joshua Reddekopp">
          <div id="center-text">Hobbies and Interests</div>
        </div>
        <div className="lower-content container-fluid">
          <div className="row justify-content-md-center">
            <div className="col col-lg-4" id="about-picture">
              <img
                className="about-image "
                src="assets/aboutme/portpic3.jpg"
                alt="profile"
              />
            </div>
            <div id="about-text" className="col-md-3 text-center">
              <h3>Hi, I'm Sean!</h3>
              <p>
                I am a born and raised Austinite. I enjoy playing tennis,
                competitive cycling, and fostering special needs feline friends!
                My favorite food is fried rice and I am a religious animal
                lover.
                <br />
                <br />I am currently looking to work as a full-stack software
                developer, while building web applications as a contractor. If
                you are interested in chatting, please feel free to reach out on
                my Contact page.
              </p>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default About;
