import React, { Component } from "react";
import { ReactComponent as Previous } from "../../assets/before.svg";
import { ReactComponent as Next } from "../../assets/next.svg";

class Etsyocalypse extends Component {
  state = {
    currentImage: 1,
  };

  clickNext() {
    let { currentImage } = this.state;
    let next = currentImage + 1;
    if (currentImage === 5) {
      this.setState({ currentImage: 1 });
    } else {
      this.setState({ currentImage: next });
    }
  }

  clickPrevious() {
    let { currentImage } = this.state;
    let previous = currentImage - 1;
    if (currentImage === 1) {
      this.setState({ currentImage: 5 });
    } else {
      this.setState({ currentImage: previous });
    }
  }

  render() {
    let { currentImage } = this.state;
    return (
      <div className="row project-row">
        <div className="col-lg-7 project-image-outer-container noselect">
          <div className="project-images-container">
            {currentImage <= 1 && (
              <img
                className="rounded noselect project-images"
                src={`assets/projects/etsy/etsy${currentImage}.gif`}
                alt="etsyocalypse pic array"
              ></img>
            )}
            {currentImage > 1 && (
              <img
                className="rounded noselect project-images"
                src={`assets/projects/etsy/etsy${currentImage}.PNG`}
                alt="etsy pic array"
              ></img>
            )}
          </div>
          <Previous
            onClick={() => this.clickPrevious()}
            className="prev-button"
            style={{
              height: "50px",
              width: "50px",
              position: "absolute",
              top: "45%",
              left: "0",
            }}
          />
          <Next
            onClick={() => this.clickNext()}
            className="next-button"
            style={{
              height: "50px",
              width: "50px",
              position: "absolute",
              top: "45%",
              right: "0",
            }}
          />
        </div>

        <div className="col-lg-5 project-text text-center">
          <p>
            Etsyocalpyse was my first project with a group of five other
            engineers. We were tasked with replicating the UI of Etsy, while
            adding any theme we wanted. We ended up on a apocalypse theme and
            decided on the name "Etsyocalypse".
            <br /> <br />
            We started off dividing up our components into wireframes. Once we
            decided on what components each of us had, we got straight to work.
            We all worked from separate repo's and decided to make this website
            function as a reverse proxy, where one component could fail but the
            rest would keep working.
            <br /> <br />
            The biggest challenge we faced during this project was conflicting
            CSS files. We ended up learning and using bootstrap. This being our
            first deployed project, we had to decide on a platform that would be
            easy to use. AWS Beanstalk was the best option. Later in the project
            we ended up redesigning the backend functionality to work with many
            more requests scaled the database significantly.
            <br />
            <br />
          </p>

          <a
            href="https://github.com/Etsyocalypse/Sean-service"
            type="button"
            className="btn btn-dark btn-lg"
            style={{
              height: "48px",
              padding: "5px 10px 10px 5px",
              backgroundColor: "white",
              boxGlow: "0px 0px -5px black",
            }}
          >
            <img id="github-logo" src="assets\github.png" alt="github icon" />
          </a>
        </div>
      </div>
    );
  }
}

export default Etsyocalypse;
