import React from "react";

const Resume = () => {
  return (
    <>
      <div id="resume-background" alt="Photo by Emile Perron">
        <div id="info">
          <div id="center-text">Story and Resume</div>
          <div className="lower-content container-fluid">
            <div
              className="row project-row justify-content-md-center"
              style={{
                marginLeft: "25px",
                marginRight: "25px",
                marginTop: "50px",
                marginBottom: "20px",
                height: "100%",
                color: "black",
              }}
            >
              <div
                className="col col-lg-10 text-center"
                style={{ padding: "30px" }}
              >
                <p>
                  Since I was young I was fascinated by programming. The ability
                  to create autonomous products has always been of interest to
                  me. In high school I became interested in a game that allowed
                  you to program in a language called E2. Although I did not
                  pursue coding in college I took a couple classes in C++ while
                  pursuing an engineering degree. In my first job for the OAG we
                  used a text-based program called TXCSES to manage our clients.
                  This program was old and outdated, and that is when I truly
                  began to experience the potential of coding. I asked my
                  manager for the handbook to programming in TXCSES and began to
                  write automated functions to not only improve productivity
                  within the office, but to perform repetitive processes
                  automatically. After working there for over a year I knew I
                  could do more, and decided to pursue my career in programming.
                  <br />
                  <br />I have always tried to create organization within an
                  unorganized group, and I love helping coworkers get through
                  any problems they may encounter. I am extremely passionate
                  about ease of use in applications. This is why I love working
                  on apps in the front end, and while I am able to make useful
                  contributions to optimizing the backend of an application, I
                  believe that the GUI on a website is much more important. I
                  also enjoy working on front-end development because I enjoy
                  the gratification of seeing my changes in action, as a user
                  would experience.
                  <br />
                  <br />
                  Personally, I love using JS and JS-React to build my websites
                  and I plan to start learning Dart/Flutter and Angular in the
                  future. Coming from using jQuery where you have to force the
                  page to reload or change using CSS changes and specific
                  functions, having changes to state re-render the DOM is much
                  more efficient for creating user-friendly websites. I have the
                  most experience using React and you can find repo’s of my work
                  @https://github.com/seanspiesman.
                </p>
              </div>
              <span
                className="col col-md-2 download-column"
                style={{
                  textAlign: "center",
                }}
              >
                <div className="download-style">
                  <p>Download Resume</p>
                  <a href="/assets/resume/SeanSpiesmanResume.pdf" download>
                    <img
                      id="download-icon"
                      src="/assets/resume/download.jpg"
                      alt="download symbol"
                    />{" "}
                  </a>
                </div>
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Resume;
