import React from "react";
import { Link } from "react-router-dom";
import "./homepage.css";

const HomePage = () => {
  return (
    <>
      <div id="home-background" alt="Image from Christopher Gower">
        <Link className="btn btn-dark" to="/Projects" id="homepage-text">
          SEE MY PROJECTS
        </Link>
      </div>

      <svg
        id="dev-logo"
        width="778"
        height="114"
        viewBox="0 0 778 114"
        fill="transparent"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M4.88281 108V5.625H33.7812C42.6875 5.625 50.5625 7.59375 57.4062 11.5312C64.25 15.4688 69.5234 21.0703 73.2266 28.3359C76.9766 35.6016 78.875 43.9453 78.9219 53.3672V59.9062C78.9219 69.5625 77.0469 78.0234 73.2969 85.2891C69.5938 92.5547 64.2734 98.1328 57.3359 102.023C50.4453 105.914 42.4062 107.906 33.2188 108H4.88281ZM18.3828 16.7344V96.9609H32.5859C42.9922 96.9609 51.0781 93.7266 56.8438 87.2578C62.6562 80.7891 65.5625 71.5781 65.5625 59.625V53.6484C65.5625 42.0234 62.8203 33 57.3359 26.5781C51.8984 20.1094 44.1641 16.8281 34.1328 16.7344H18.3828Z"
          stroke="#FFFFFF"
          strokeWidth="5"
        />
        <path
          d="M157.25 60.6797H112.883V96.9609H164.422V108H99.3828V5.625H163.719V16.7344H112.883V49.6406H157.25V60.6797Z"
          stroke="#FFFFFF"
          strokeWidth="5"
        />
        <path
          d="M215.117 90.0703L244.367 5.625H259.133L221.164 108H209.211L171.312 5.625H186.008L215.117 90.0703Z"
          stroke="#FFFFFF"
          strokeWidth="5"
        />
        <path
          d="M330.781 60.6797H286.414V96.9609H337.953V108H272.914V5.625H337.25V16.7344H286.414V49.6406H330.781V60.6797Z"
          stroke="#FFFFFF"
          strokeWidth="5"
        />
        <path
          d="M368.328 96.9609H416.844V108H354.758V5.625H368.328V96.9609Z"
          stroke="#FFFFFF"
          strokeWidth="5"
        />
        <path
          d="M506.352 60.1172C506.352 70.1484 504.664 78.9141 501.289 86.4141C497.914 93.8672 493.133 99.5625 486.945 103.5C480.758 107.438 473.539 109.406 465.289 109.406C457.227 109.406 450.078 107.438 443.844 103.5C437.609 99.5156 432.758 93.8672 429.289 86.5547C425.867 79.1953 424.109 70.6875 424.016 61.0312V53.6484C424.016 43.8047 425.727 35.1094 429.148 27.5625C432.57 20.0156 437.398 14.25 443.633 10.2656C449.914 6.23438 457.086 4.21875 465.148 4.21875C473.352 4.21875 480.57 6.21094 486.805 10.1953C493.086 14.1328 497.914 19.875 501.289 27.4219C504.664 34.9219 506.352 43.6641 506.352 53.6484V60.1172ZM492.922 53.5078C492.922 41.3672 490.484 32.0625 485.609 25.5938C480.734 19.0781 473.914 15.8203 465.148 15.8203C456.617 15.8203 449.891 19.0781 444.969 25.5938C440.094 32.0625 437.586 41.0625 437.445 52.5938V60.1172C437.445 71.8828 439.906 81.1406 444.828 87.8906C449.797 94.5938 456.617 97.9453 465.289 97.9453C474.008 97.9453 480.758 94.7812 485.539 88.4531C490.32 82.0781 492.781 72.9609 492.922 61.1016V53.5078Z"
          stroke="#FFFFFF"
          strokeWidth="5"
        />
        <path
          d="M540.102 67.9219V108H526.602V5.625H564.359C575.562 5.625 584.328 8.48438 590.656 14.2031C597.031 19.9219 600.219 27.4922 600.219 36.9141C600.219 46.8516 597.102 54.5156 590.867 59.9062C584.68 65.25 575.797 67.9219 564.219 67.9219H540.102ZM540.102 56.8828H564.359C571.578 56.8828 577.109 55.1953 580.953 51.8203C584.797 48.3984 586.719 43.4766 586.719 37.0547C586.719 30.9609 584.797 26.0859 580.953 22.4297C577.109 18.7734 571.836 16.875 565.133 16.7344H540.102V56.8828Z"
          stroke="#FFFFFF"
          strokeWidth="5"
        />
        <path
          d="M675.312 60.6797H630.945V96.9609H682.484V108H617.445V5.625H681.781V16.7344H630.945V49.6406H675.312V60.6797Z"
          stroke="#FFFFFF"
          strokeWidth="5"
        />
        <path
          d="M736.836 66.5859H712.789V108H699.219V5.625H733.109C744.641 5.625 753.5 8.25 759.688 13.5C765.922 18.75 769.039 26.3906 769.039 36.4219C769.039 42.7969 767.305 48.3516 763.836 53.0859C760.414 57.8203 755.633 61.3594 749.492 63.7031L773.539 107.156V108H759.055L736.836 66.5859ZM712.789 55.5469H733.531C740.234 55.5469 745.555 53.8125 749.492 50.3438C753.477 46.875 755.469 42.2344 755.469 36.4219C755.469 30.0938 753.57 25.2422 749.773 21.8672C746.023 18.4922 740.586 16.7812 733.461 16.7344H712.789V55.5469Z"
          stroke="#FFFFFF"
          strokeWidth="5"
        />
      </svg>
    </>
  );
};

export default HomePage;
