import React from "react";

const NotFound = () => {
  return (
    <div id="not-found-background" alt="Photo by Markus Spiske">
      <div id="center-text">Page not found...</div>
    </div>
  );
};

export default NotFound;
