import React, { Fragment, Component } from "react";
import "./App.css";
import { AnimatedSwitch } from "react-router-transition";
import { Route, Switch, withRouter } from "react-router-dom";
import About from "./components/AboutPage";
import Projects from "./components/Projects";
import Resume from "./components/ResumePage";
import Contact from "./components/ContactPage";
import NotFound from "./components/NotFound";
import NavBar from "./components/NavBar";
import HomePage from "./components/Homepage/HomePage";

class App extends Component {
  render() {
    return (
      <div style={{ fontFamily: "Roboto, sans-serif !important" }}>
        <Fragment>
          <NavBar />
          <Switch>
            <AnimatedSwitch
              atEnter={{ opacity: 0 }}
              atLeave={{ opacity: 0 }}
              atActive={{ opacity: 1 }}
              className="switch-wrapper"
            >
              <Route exact path="/" component={HomePage} />
              <Route exact path="/About" component={About} />
              <Route path="/Projects" component={Projects} />
              <Route path="/resume" component={Resume} />
              <Route path="/contact" component={Contact} />
              <Route component={NotFound} />
            </AnimatedSwitch>
          </Switch>
        </Fragment>
      </div>
    );
  }
}

export default withRouter(App);
