import React from "react";

const Contact = () => {
  return (
    <>
      <div id="contact-background" alt="Photo by Obi Onyeador">
        <div id="center-text">Contact Me</div>
      </div>
      <div className="lower-content container-fluid">
        <div className="row justify-content-md-center">
          <div className="col col-lg-4" id="about-picture">
            <img
              className="about-image "
              src="assets/aboutme/pic1.jpg"
              alt="about pic"
            />
          </div>
          <div id="about-text" className="col-md-3 text-center">
            <h3>Thanks for visiting!</h3>
            <p>
              If you would like to contact me, feel free to email me at{" "}
              <a href="mailto:sean.spies@gmail.com">sean.spies@gmail.com</a>.
              <br />
              <br />
              You can view more of my work and my LinkedIn below.
              <br />
              <br />
              <a href="https://github.com/seanspiesman">
                <img
                  style={{
                    height: "60px",
                    padding: "5px 10px 10px 5px",
                    backgroundColor: "white",
                    boxGlow: "0px 0px -5px black",
                  }}
                  src="/assets/githublogo.png"
                  alt="github link"
                />
              </a>
              {"     "}
              <a
                href="https://www.linkedin.com/in/sean-spiesman/"
                style={{
                  marginLet: "20px",
                  padding: "5px 10px 10px 5px",
                  backgroundColor: "white",
                  boxGlow: "0px 0px -5px black",
                }}
              >
                <img
                  style={{ height: "80px" }}
                  src="/assets/linkedin.jpg"
                  alt="linkedin link"
                />
              </a>
              <br />
              <br />
              Lastly, if you would like to follow our foster Instagram, here is
              the link!
              <br />
              <br />
              <a href="https://www.instagram.com/atx_foster_pawrents/">
                <img
                  style={{
                    height: "60px",
                    padding: "5px 10px 10px 5px",
                    backgroundColor: "white",
                    boxGlow: "0px 0px -5px black",
                  }}
                  src="/assets/instagram-logo.png"
                  alt="github link"
                />
              </a>
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Contact;
