import React, { Component } from "react";
import { ReactComponent as Previous } from "../../assets/before.svg";
import { ReactComponent as Next } from "../../assets/next.svg";

class Gigl extends Component {
  state = {
    imageArray: [1, 2, 3, 4, 5, 6, 7, 8],
    currentImage: 1,
  };

  clickNext() {
    let { currentImage } = this.state;
    let next = currentImage + 1;
    if (currentImage === 9) {
      this.setState({ currentImage: 1 });
    } else {
      this.setState({ currentImage: next });
    }
  }

  clickPrevious() {
    let { currentImage } = this.state;
    let previous = currentImage - 1;
    if (currentImage === 1) {
      this.setState({ currentImage: 8 });
    } else {
      this.setState({ currentImage: previous });
    }
  }

  render() {
    let { currentImage } = this.state;
    return (
      <div className="row project-row">
        <div className="col-lg-7 project-image-outer-container noselect">
          <div className="project-images-container">
            <img
              className="rounded noselect project-images"
              src={`assets/projects/gigl/gigl${currentImage}.PNG`}
              alt="gigl mainpage"
            ></img>
          </div>
          <Previous
            onClick={() => this.clickPrevious()}
            style={{
              height: "50px",
              width: "50px",
              position: "absolute",
              top: "45%",
              left: "0",
            }}
            className="prev-button"
          />
          <Next
            onClick={() => this.clickNext()}
            className="next-button"
            style={{
              height: "50px",
              width: "50px",
              position: "absolute",
              top: "45%",
              right: "0",
            }}
          />
        </div>

        <div className="col-lg-5 project-text text-center">
          <p>
            Gigl is a website that allows clients and contractors to connect by
            the client posting a gig that they need skilled work for. The
            contractor/performer can then login and see all the possible "gigs"
            and apply for whichever ones they want. The client will then get a
            list of contractors wanting to work and can select which ones he or
            she wants.
            <br />
            <br />
            This website was built in collaboration with 11 other engineers
            using an agile workflow. Personally I worked on the client page with
            two other engineers and utilized account and form authentication.
            <br />
            <br />
            The technologies utilized on this application included Javascript,
            React, Node.js, Bootstrap, AWS, Express, Heroku and MongoDB. Our
            workflow consisted of creating a wireframe based on a clients
            expectations, and separating components out to smaller groups of
            engineers based on those wireframes. We then came together after
            building the layout for our front-end components and discussed the
            best database schema.
          </p>
          <br />
          <div>
            <a
              href="https://hratx47-gigl.herokuapp.com/"
              type="button"
              className="btn btn-dark btn-lg"
            >
              Website Link
            </a>{" "}
            <a
              href="https://github.com/hratx47-gigl/Gigl-Website"
              type="button"
              className="btn btn-dark btn-lg"
              style={{
                height: "48px",
                padding: "5px 10px 10px 5px",
                backgroundColor: "white",
                boxGlow: "0px 0px -5px black",
              }}
            >
              <img id="github-logo" src="assets\github.png" alt="github icon" />
            </a>
          </div>
        </div>
      </div>
    );
  }
}

export default Gigl;
