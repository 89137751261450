import React, { Component } from "react";
import { ReactComponent as Previous } from "../../assets/before.svg";
import { ReactComponent as Next } from "../../assets/next.svg";

class Eventer extends Component {
  state = {
    imageArray: [1, 2, 3, 4, 5, 6, 7, 8],
    currentImage: 1,
  };

  clickNext() {
    let { currentImage } = this.state;
    let next = currentImage + 1;
    if (currentImage === 8) {
      this.setState({ currentImage: 1 });
    } else {
      this.setState({ currentImage: next });
    }
  }

  clickPrevious() {
    let { currentImage } = this.state;
    let previous = currentImage - 1;
    if (currentImage === 1) {
      this.setState({ currentImage: 8 });
    } else {
      this.setState({ currentImage: previous });
    }
  }

  render() {
    let { currentImage } = this.state;
    return (
      <div className="row project-row">
        <div className="col-lg-7 project-image-outer-container noselect">
          <div className="project-images-container">
            <img
              className="rounded noselect project-images"
              src={`assets/projects/eventer/eventer${currentImage}.PNG`}
              alt="eventer mainpage"
            ></img>
          </div>
          <Previous
            onClick={() => this.clickPrevious()}
            style={{
              height: "50px",
              width: "50px",
              position: "absolute",
              top: "45%",
              left: "0",
            }}
            className="prev-button"
          />
          <Next
            onClick={() => this.clickNext()}
            className="next-button"
            style={{
              height: "50px",
              width: "50px",
              position: "absolute",
              top: "45%",
              right: "0",
            }}
          />
        </div>

        <div className="col-lg-5 project-text text-center">
          <p className="par-text">
            Eventer is a web application that allows users to join, view, and
            create events, while following other users and designing a
            personalized profile.
            <br />
            <br />
            I started out this project wanting to setup something with
            technologies that I wasn't very familiar with. Using multiple guides
            and various trial and error. I was finally able to put this project
            together. I built the repo step by step, slowly incorporating more
            features as to not take too big of steps.
            <br />
            <br />
            This was my most ambitious project to date because I decided to
            learn all new JavaScript technologies. I ended up using Redux,
            Semantic-ui-react, and Firebase, along with various libraries for
            form authentication and validation.
            <br />
            <br />I recently published the application online and while it was
            very challenging, it has made me find new love for Firebase and
            Redux. Both of these tools can enable anybody to build various
            differnt applications.
            <br />
          </p>
          <div stlye={{ justifyContent: "center" }}>
            <a
              href="https://eventer-firebase.web.app/"
              type="button"
              className="btn btn-dark btn-lg"
            >
              Website Link
            </a>{" "}
            <a
              href="https://github.com/seanspiesman/Eventer"
              type="button"
              className="btn btn-dark btn-lg"
              style={{
                height: "48px",
                padding: "5px 10px 10px 5px",
                backgroundColor: "white",
                boxGlow: "0px 0px -5px black",
              }}
            >
              <img id="github-logo" src="assets\github.png" alt="github icon" />
            </a>
          </div>
        </div>
      </div>
    );
  }
}

export default Eventer;
