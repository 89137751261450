import React, { Component } from "react";
import { ReactComponent as Previous } from "../../assets/before.svg";
import { ReactComponent as Next } from "../../assets/next.svg";

class Bandana extends Component {
  state = {
    currentImage: 1,
  };

  clickNext() {
    let { currentImage } = this.state;
    let next = currentImage + 1;
    if (currentImage === 9) {
      this.setState({ currentImage: 1 });
    } else {
      this.setState({ currentImage: next });
    }
  }

  clickPrevious() {
    let { currentImage } = this.state;
    let previous = currentImage - 1;
    if (currentImage === 1) {
      this.setState({ currentImage: 9 });
    } else {
      this.setState({ currentImage: previous });
    }
  }

  render() {
    let { currentImage } = this.state;
    return (
      <div className="row project-row">
        <div className="col-lg-7 project-image-outer-container noselect">
          <div className="project-images-container">
            {currentImage <= 4 && (
              <img
                className="rounded noselect project-images"
                src={`assets/projects/bandana/bandana${currentImage}.gif`}
                alt="bandana nana pic array"
              ></img>
            )}
            {currentImage > 4 && (
              <img
                className="rounded noselect project-images"
                src={`assets/projects/bandana/bandana${currentImage}.png`}
                alt="bandana nana pic array"
              ></img>
            )}
          </div>
          <Previous
            onClick={() => this.clickPrevious()}
            className="prev-button"
            style={{
              height: "50px",
              width: "50px",
              position: "absolute",
              top: "45%",
              left: "0",
            }}
          />
          <Next
            onClick={() => this.clickNext()}
            className="next-button"
            style={{
              height: "50px",
              width: "50px",
              position: "absolute",
              top: "45%",
              right: "0",
            }}
          />
        </div>

        <div className="col-lg-5 project-text text-center">
          <p>
            Bandana Nana was my first stand alone project. I took everything I
            knew about front-end and back-end development and decided to put
            myself to the test. I had only been coding professionally for a few
            months so I setup my folder organization and then got to work.
            <br />
            <br />
            Bandana Nana was setup as a website for my girlfriend where we would
            be able to give people doggie bandanas and in return they had the
            option to donate to our current fosters needs. I had just started
            using bootstrap for the first time and decided to use it for the
            website's UI.
            <br />
            <br />
            The biggest challenge I faced during this project was using account
            authentication throughout each page so certain users could only
            control their personal carts. I had to setup a complex series of
            classes and state within multiple components that all had the
            ability to talk with each other.
            <br />
            <br />
            The technologies utilized on this application included Javascript,
            React, Node.js, Bootstrap, Google Cloud, AWS, Express, MySQL, and
            Docker.
          </p>

          <a
            href="https://github.com/seanspiesman/bandanaNana"
            type="button"
            className="btn btn-dark btn-lg"
            style={{
              height: "48px",
              padding: "5px 10px 10px 5px",
              backgroundColor: "white",
              boxGlow: "0px 0px -5px black",
            }}
          >
            <img id="github-logo" src="assets\github.png" alt="github icon" />
          </a>
        </div>
      </div>
    );
  }
}

export default Bandana;
