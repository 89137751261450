import React, { Component } from "react";
import { NavLink, Link, withRouter } from "react-router-dom";
import $ from "jquery";

class NavBar extends Component {
  state = {
    page: ["About", "Projects", "Resume", "Contact"],
  };

  componentDidMount() {
    let location = this.props.location.pathname.slice(1);
    location.charAt(0).toUpperCase();
    if (location.length === 0) {
      location = "Home";
    }
    this.setState({ currentTab: location }, () => this.focusEvent(location));
    $(`#${this.state.currentTab}`).css({
      color: "rgb(255, 216, 110)",
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.currentTab !== this.props.location.pathname.slice(1) &&
      this.props.location.pathname !== "/" &&
      this.props.location.pathname.slice(1) === "Projects"
    ) {
      this.focusEvent("Projects");
    }
  }

  focusEvent = (focus) => {
    const { currentTab } = this.state;
    if (currentTab !== focus) {
      $(`#${currentTab}`).css({ color: "white" });
      $(`#${focus}`).css({
        color: "rgb(255, 216, 110)",
        textShadow: "0px 0px 5px black",
      });
      this.setState({ currentTab: focus });
    } else {
      $(`#${focus}`).css({ color: "rgb(255, 216, 110)" });
    }
  };

  render() {
    const { page } = this.state;
    return (
      <nav
        style={{ zIndex: "1000" }}
        className="navbar sticky-top transparent navbar-inverse "
      >
        <Link
          id="Home"
          style={{ textShadow: "0px 0px 5px black" }}
          className="navbar-brand"
          as={NavLink}
          to="/"
          onClick={() => {
            this.focusEvent("Home");
          }}
        >
          Sean Spiesman
        </Link>
        <form className="form-inline my-2 my-lg-0">
          {page.map((page, index) => {
            return (
              <Link
                onClick={() => {
                  this.focusEvent(page);
                }}
                as={NavLink}
                to={`/${page}`}
                key={index}
                style={{ textShadow: "0px 0px 5px black" }}
                className="btn menu-items my-2 my-sm-0"
                id={page}
              >
                {page}
              </Link>
            );
          })}
        </form>
      </nav>
    );
  }
}

export default withRouter(NavBar);
